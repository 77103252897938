
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Watch} from 'vue-property-decorator';

    @Component<PhoneInput>({
        props: {
            label: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            prefix: {
                type: String,
            },
            outlined: {
                type: String,
                default:false
            },
            dense: {
                type: String,
                default:false
            }
        },
    })
    export default class PhoneInput extends Vue {
        public phone: any|null = null;
        public phoneRule: boolean|null = null;
        public regex = /^[67]\d{8}$/;

        public phoneRules = [
            (v: string) => !!v || 'Le téléphone est obligatoire',
            (v: string) => this.regex.test(v) || 'Le téléphone doit commencer par 6 ou 7 et doit etre composé de 9 chiffres',
        ];

        @Watch('phone')
        private handleChange() {
            if (this.regex.test(this.phone)) {
                this.phoneRule = true;
                this.$emit('submit', '+33'+this.phone);
            } else {
                this.phoneRule = false;
                this.$emit('submit', '');
            }
        }
    }
